<template>
  <div>

  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      items: [],
      loading: false,
      datos_autogestor_preconsulta: {
        "id": 6,
        "layout_id": 62,
        "layout": {
          "id": 62,
          "nombre": "Consolidado de Tickets",
          "descripcion": "Consolidado de Tickets",
          "tema_id": 3,
          "tema": {
            "id": 3,
            "dominio_id": 1,
            "opcion_id": null,
            "tiene_subopciones": 0,
            "valor_texto": "Transversales",
            "valor_numerico": 3,
            "valor_booleano": 0,
            "descripcion": "Transversales",
            "abreviatura": "TR",
            "orden": 0,
            "editable": 1,
            "borrable": 0,
            "activo": 1
          },
          "tipo_carga_id": 2,
          "tipoCarga": null,
          "version": "1",
          "delimitadorCampo": ";",
          "delimitadorTexto": ";",
          "formatoFecha": "YYYY-MM-DD",
          "erroresPermitidos": 0,
          "validarLlavePrimaria": 1,
          "permitirDuplicados": 0,
          "activo": 1
        },
        "categoria_id": 88,
        "categoria": "GA - CAF",
        "nombre": "Gestor de Tickets",
        "descripcion": "Consulta general de información de Tickets",
        "campos_consulta": "[\"MES_SOLICITUD\",\"MES TRAMITADA SAP\",\"SOPORTE\",\"FN/ASUNTO\",\"Q ACTIVOS\",\"CLASIFICACIÓN\",\"ESTATUS CIERRE SHARE POINT\",\"ESTATUS SAP\",\"CONCEPTO SOLICITUD\",\"MOTIVO RECHAZO\",\"GESTIONADO POR GA:\",\"APROBADOR\",\"ACTIVO / ACTIVO DESTINO\",\"SN_DESTINO\",\"ACTIVO ORIGEN\",\"SN_ORIGEN\",\"Valor Traslado/ Baja parcial\",\"TEXTO BAJA/TRASLADO\",\"DENOMINACION\",\"NO. INVENTARIO\",\"TIPO DE OPERACIÓN\",\"REGIÓN\",\"CECO\",\"Nombre Centro de Costo\",\"VICEPRESIDENCIA\",\"SIGLA VICEPRESIDENCIA\",\"GERENCIA\",\"SIGLA GERENCIA\",\"NIVEL 3\",\"SIGLA NIVEL 3\",\"FECHA CREACIÓN\",\"FECHA APROBACIÓN\",\"FECHA CIERRE SOLICITUD\",\"TIEMPO DE EJECUCION\",\"USUARIO\",\"USUARIO2\",\"VICEPRESIDENCIA USUARIO\",\"SIGLA VICE USUARIO\",\"REGIÓN USUARIO\",\"OBSERVACIONES\",\"MES CIERRE SHAREPOINT\",\"ESTATUS REGISTRO\",\"REGIÓN PROFESIONAL REGIONAL\",\"CANTIDAD TICKETS\",\"SOLPED\",\"CÓDIGO DE EQUIPO\",\"CÓDIGO UT\",\"TIEMPO EN MINUTOS\",\"ID\",\"CARGO_GESTOR\",\"NombreAprobadorLiderDelProyecto\",\"NombreAprobadorAdministrador\",\"NombreAprobadorAreaMantenimiento\"]",
        "campos_panoramico_1": "[]",
        "campos_panoramico_2": "[]",
        "campos_panoramico_3": "[]",
        "campos_panoramico_4": "[]",
        "roles_acceso": "[\"AutoGestorConsulta\",\"Autogestor-Administrador\",\"Administrador\"]",
        "activo": 1
      }
    };
  },
  components: {

  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Autogestor - Consulta"))
      this.$router.push("/dashboard");

      console.log('datos_autogestor_preconsulta')
      console.log(this.datos_autogestor_preconsulta)
      localStorage.setItem("datos_autogestor_preconsulta", JSON.stringify(this.datos_autogestor_preconsulta))

      this.$router.push({ path: `/autogestor/categorias/consultas/consulta/` });

      //this.fetchItems("?activo=1&categoria_id="+this.categoria.id);
  },
  methods: {
    fetchItems(cadenaBusqueda) {
      this.loading = true;
      definicionConsultaService.getItems(cadenaBusqueda).then(
        (response) => {
          this.items = response.data;
          
          this.items = this.items.filter( this.checkRoles )

          console.log(this.items);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    
  },
};
</script>
